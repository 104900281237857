// src/components/SearchComponent.tsx

import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from "@mui/material";

interface SearchResult {
  cosine_distance: number;
  archived: boolean;
  creation_date: string;
  description: string;
  fork: boolean;
  fork_count: number;
  homepage_link: string | null;
  id: number;
  last_update_date: string;
  maintainer_id: string;
  name: string;
  org_id: string | null;
  primary_language: string | null;
  readme_url: string;
  repo_accept_sponsorship: boolean;
  star_count: number;
  url: string;
  watching: number;
  master_pushed_at: string;
  non_english: boolean;
}

function SearchComponent() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const [filters, setFilters] = useState({
    star_count: undefined,
    primary_language: undefined,
    creation_date: undefined,
    last_update_date: undefined,
    repo_accept_sponsorship: undefined,
    is_fork: undefined,
    watching_count: undefined,
    master_pushed_at: undefined,
    non_english: undefined,
    star_growth_tier: undefined,
  });

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (value === "") {
      setFilters((prev) => ({
        ...prev,
        [name]: undefined, // we use undefined to remove the filter
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSearch = async () => {
    setLoading(true);

    try {
      const response = await fetch("https://api.devmark.ai/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });

      const data = await response.json();
      if (
        data.success &&
        data.result &&
        Array.isArray(data.result.search_results)
      ) {
        setResults(data.result.search_results);
      } else {
        // handle any error or unexpected structure
        console.error("Unexpected data structure:", data);
      }
    } catch (error) {
      console.error("Error fetching the data", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, v]) => v !== undefined)
  );
  const bodyData = {
    text: query,
    user_id: "test-app",
    limit: 50,
    filters: filteredFilters,
  };

  return (
    <div>
      {/* Filters */}
      <div style={{ marginBottom: "10px" }}>
        <input
          type="number"
          name="star_count"
          placeholder="Star count >"
          onChange={handleFilterChange}
        />
        <input
          type="number"
          name="watching_count"
          placeholder="Watching count >"
          onChange={handleFilterChange}
        />
        <p>Created date after</p>
        <input
          type="date"
          name="creation_date"
          placeholder="Creation date after"
          onChange={handleFilterChange}
        />
        <p>Last updated date after</p>
        <input
          type="date"
          name="last_update_date"
          placeholder="Last update after"
          onChange={handleFilterChange}
        />
        <p>Last pushed date to master branch after</p>
        <input
          type="date"
          name="master_pushed_at"
          placeholder="Last pushed to master branch after"
          onChange={handleFilterChange}
        />
        <p>Sponsorship</p>
        <select name="repo_accept_sponsorship" onChange={handleFilterChange}>
          <option value="">Any</option>
          <option value="true">Accepts sponsorship</option>
          <option value="false">Doesn't accept sponsorship</option>
        </select>
        <p>Star count growth rate</p>
        <select name="star_growth_tier" onChange={handleFilterChange}>
          <option value="">Any</option>
          <option value="top_10_percent">Top 10 percent</option>
          <option value="top_25_percent">Top 25 percent</option>
          <option value="top_50_percent">Top 50 percent</option>
        </select>
        <p>Non-English</p>
        <select name="non_english" onChange={handleFilterChange}>
          <option value="">Any</option>
          <option value="true">Non-english repos only</option>
          <option value="false">English repos only</option>
        </select>
        <p>Fork</p>
        <select name="is_fork" onChange={handleFilterChange}>
          <option value="">Any</option>
          <option value="true">Is a fork</option>
          <option value="false">Not a fork</option>
        </select>
        <p></p>
        <input
          type="text"
          name="primary_language"
          placeholder="Language"
          onChange={handleFilterChange}
        />
      </div>

      {/* Search Bar */}
      <div>
        <input
          type="text"
          value={query}
          onChange={handleChange}
          style={{
            fontSize: "18px",
            padding: "10px",
            width: "300px",
            borderRadius: "5px",
          }}
        />
        <button onClick={handleSearch}>Search</button>
      </div>

      {/* Spinner */}
      {loading && <div>Loading...</div>}

      {/* Results Table */}
      <TableContainer component={Paper}>
        <Table style={{ width: "80%", margin: "20px auto" }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Cosine Distance
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Description</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Accepts Sponsorship
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Star Count</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Archived</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Creation Date
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Last Update</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Master pushed at
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Non-English</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Fork</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Fork Count</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Homepage</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Primary Language
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Watching</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((result, index) => (
              <TableRow key={index}>
                <TableCell>{result.cosine_distance.toFixed(4)}</TableCell>
                <TableCell>
                  <Link
                    href={result.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {result.name}
                  </Link>
                </TableCell>
                <TableCell style={{ minWidth: "300px" }}>
                  {result.description}
                </TableCell>
                <TableCell>
                  {result.repo_accept_sponsorship ? "Yes" : "No"}
                </TableCell>
                <TableCell>{result.star_count}</TableCell>
                <TableCell>{result.archived ? "Yes" : "No"}</TableCell>
                <TableCell>{result.creation_date}</TableCell>
                <TableCell>{result.last_update_date}</TableCell>
                <TableCell>{result.master_pushed_at}</TableCell>
                <TableCell>{result.non_english ? "Yes" : "No"}</TableCell>
                <TableCell>{result.fork ? "Yes" : "No"}</TableCell>
                <TableCell>{result.fork_count}</TableCell>
                <TableCell>
                  {result.homepage_link ? (
                    <Link
                      href={result.homepage_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {result.homepage_link}
                    </Link>
                  ) : (
                    "N/A"
                  )}
                </TableCell>
                <TableCell>{result.primary_language || "N/A"}</TableCell>
                <TableCell>{result.watching}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default SearchComponent;
